<template>
    <header class="m-header js-m-header">
      <div v-if="$root.site === 'abaatas'" style="position: fixed;z-index: 10;bottom: 0; width: 100vw;display: flex; justify-content: center">
        <img style="max-width: inherit; transform: scaleX(-1);"  src="../assets/img/Home/Snow_group.svg" alt="">
        <img style="max-width: inherit;"  src="../assets/img/Home/Snow_group.svg" alt="">
        <img style="max-width: inherit; position: relative; left: -18px; transform: scaleX(-1);"  src="../assets/img/Home/Snow_group.svg" alt="">
      </div>

        <div v-if="widthWindow >= 768" class="m-header__wrap container">
            <div class="m-header__inner js-search-hide">
                <div class="m-header__wrapper">
                    <div class="m-header__box">
                        <div class="m-header__item">
                            <div class="m-header-line">
                                <div class="m-header-line__inner">
                                    <div class="m-header-line__wrapper">
                                        <div class="m-header-line__box">
                                            <div class="m-header-line__item">

                                              <router-link v-if="$root.site === 'picunda'" to="/picunda" class="m-header__logo" title="Соблюдайте дистанцию, пользуйтесь дезинфицирующими средствами, откажитесь от рукопожатий, берегите свое здоровье.">
                                                <div class="newYear-header-logo">
                                                  <img class="newYear-header-logo__main" src="../assets/general/abaata.svg" alt="">
<!--                                                  <div class="newYear-header-logo__cap">-->
<!--                                                    <img src="../assets/img/Home/cap_logo.svg" alt="" >-->
<!--                                                  </div>-->
                                                </div>
                                              </router-link>
                                              <router-link v-else to="/abaata" class="m-header__logo" title="Соблюдайте дистанцию, пользуйтесь дезинфицирующими средствами, откажитесь от рукопожатий, берегите свое здоровье.">
                                                <div class="newYear-header-logo">
                                                  <img class="newYear-header-logo__main" src="../assets/img/header/header__logo.svg" alt="">
<!--                                                  <div class="newYear-header-logo__cap">-->
<!--                                                    <img src="../assets/img/Home/cap_logo.svg" alt="" >-->
<!--                                                  </div>-->
                                                </div>
                                              </router-link>
                                            </div>
                                        </div>
                                        <div class="m-header-line__box" v-if="menu[$root.site].length && $root.site === 'abaata'" >
                                            <div class="m-header-line__item">
                                                <div class="m-header-menu">
                                                    <button class="m-header-menu-btn" type="button">
                                                        <div class="m-header-menu-btn__list">
                                                            <div class="m-header-menu-btn__box"></div>
                                                            <div class="m-header-menu-btn__box"></div>
                                                            <div class="m-header-menu-btn__box"></div>
                                                        </div>
                                                        <div class="m-header-menu-btn__text">МЕНЮ</div>
                                                    </button>
                                                    <div class="m-header-menu-wrap">
                                                        <div class="m-header-menu-wrap__cintainer container">
                                                            <div class="m-header-menu-wrap__inner">
                                                                <div class="m-header-menu-wrap__wrapper">
                                                                    <div v-for="(m,i) in menu[$root.site]" :key="i+'menu'+m.link" class="m-header-menu-wrap__box">
                                                                        <div class="m-header-menu-wrap__item">
                                                                            <router-link :to="'/'+$root.site+m.link" class="m-header-menu-wrap__link _submenu" target="_self">
                                                                                <div class="m-header-menu-wrap__content">
                                                                                    <div class="m-header-menu-wrap__icon-box">
                                                                                        <img :src="m.img" alt="">
                                                                                    </div>
                                                                                    <div class="m-header-menu-wrap__name">{{m.name}}</div>
                                                                                </div>
                                                                            </router-link>
                                                                            <div class="m-header-menu-sub" v-if="m.child && m.child.length">
                                                                                <div class="m-header-menu-sub__container container">
                                                                                    <div class="m-header-menu-sub__inner">
                                                                                        <div class="m-header-menu-sub__wrapper">
                                                                                            <div v-for="(child, c) in m.child" :key="i+c+'child'+child.link" class="m-header-menu-sub__box">
                                                                                                <div class="m-header-menu-sub__item">
                                                                                                    <router-link :to="'/'+$root.site+child.link" class="m-header-menu-sub__link " target="_self"><span>{{ child.name }}</span></router-link>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--div class="m-header-line__box">
                                            <div class="m-header-line__item">
                                                <router-link to="/afisha/" class="m-header-link " target="_self">
                                                    <div class="m-header-link__icon _type-1">
                                                        <img src="../assets/img/header/header__afisha.svg" alt="">
                                                    </div>
                                                    <div class="m-header-link__text">Афиша</div>
                                                </router-link>
                                            </div>
                                        </div-->
                                        <div class="m-header-line__box" style="display: none">
                                            <div class="m-header-line__item">
                                                <router-link :to="'/'+$root.site+'/special-offers'" class="m-header-link " target="_self">
                                                    <div class="m-header-link__icon">
                                                        <img src="../assets/img/header/header__spec.svg" alt="">
                                                    </div>
                                                    <div class="m-header-link__text">Спецпредложения</div>
                                                </router-link >
                                            </div>
                                        </div>
                                        <div class="m-header-line__box" v-if="$root.site === 'picunda'">
                                          <div class="m-header-line__item">
                                            <router-link to="/picunda/offers" class="m-header-link " target="_self">
                                              <div class="m-header-link__icon">
                                                <img src="../assets/img/header/menu/razmeshenie2.svg" alt="">
                                              </div>
                                              <div class="m-header-link__text">Размещение</div>
                                            </router-link>
                                          </div>
                                        </div>
                                        <div class="m-header-line__box" v-if="$root.site === 'abaata'">
                                            <div class="m-header-line__item">
                                                <router-link to="/abaata/about" class="m-header-link " target="_self">
                                                    <div class="m-header-link__icon">
                                                        <img src="../assets/img/header/header__info.svg" alt="">
                                                    </div>
                                                    <div class="m-header-link__text">О нас</div>
                                                </router-link>
                                            </div>
                                        </div>
                                        <div class="m-header-line__box">
                                          <div class="m-header-line__item">
                                            <router-link :to="'/'+$root.site+'/about/contacts/'" class="m-header-link " target="_self">
                                              <div class="m-header-link__icon">
                                                <img src="../assets/img/header/header__contacts.svg" alt="">
                                              </div>
                                              <div class="m-header-link__text">Контакты</div>
                                            </router-link>
                                          </div>
                                        </div>
                                      <div class="m-header-line__box" v-if="$root.site === 'abaata'">
                                        <div class="m-header-line__item">
                                          <router-link to="/abaata/about/web-cams/" class="m-header-link " target="_self">
                                            <div class="m-header-link__icon">
                                              <img src="../assets/img/header/cams.svg" style="width: 16px;height: 16px;" alt="">
                                            </div>
                                            <div class="m-header-link__text">WEB-камера</div>
                                          </router-link>
                                        </div>
                                      </div>
                                      <div class="m-header-line__box" v-if="$root.site === 'abaata'">
                                        <div class="m-header-line__item">
                                          <router-link to="/abaata/price" class="m-header-link " target="_self">
                                            <div class="m-header-link__icon">
                                              <img src="../assets/img/header/dollar.svg" style="width: 16px;height: 16px;" alt="">
                                            </div>
                                            <div class="m-header-link__text">Цены</div>
                                          </router-link>
                                        </div>
                                      </div>
<!--                                      <div class="m-header-line__box" v-if="$root.site === 'picunda'">
                                        <div class="m-header-line__item">
                                          <router-link to="/picunda/price" class="m-header-link " target="_self">
                                            <div class="m-header-link__icon">
                                              <img src="../assets/img/header/dollar.svg" style="width: 16px;height: 16px;" alt="">
                                            </div>
                                            <div class="m-header-link__text">Цены</div>
                                          </router-link>
                                        </div>
                                      </div>-->
                                      <div class="m-header-line__box" v-if="$root.site === 'picunda'">
                                        <div class="m-header-line__item">
                                          <router-link to="/picunda/services" class="m-header-link " target="_self">
                                            <div class="m-header-link__icon">
                                              <img src="../assets/img/header/header__spec.svg" style="width: 16px;height: 16px;" alt="">
                                            </div>
                                            <div class="m-header-link__text">Развлечения</div>
                                          </router-link>
                                        </div>
                                      </div>
                                      <div class="m-header-line__box" v-if="$root.site === 'abaata'">
                                        <div class="m-header-line__item">
                                          <router-link to="/abaata/hair-clinic" class="m-header-link " target="_self">
<!--                                            <div class="m-header-link__icon">
                                              <img src="../assets/img/header/dollar.svg" style="width: 16px;height: 16px;" alt="">
                                            </div>-->
                                            <div class="m-header-link__text">Клиника трансплантации волос доктора Халила Коча</div>
                                          </router-link>
                                        </div>
                                      </div>
                                      <div class="m-header-line__box">
                                        <div class="m-header-line__item">
                                          <a href="#" class="m-header-link " @click="selectSite('picunda')" v-if="$root.site === 'abaata'">
                                            <div class="m-header-link__icon">
                                              <img src="../assets/img/header/header__contacts.svg" alt="">
                                            </div>
                                            <div class="m-header-link__text">Абаата Пицунда</div>
                                          </a>
                                          <a href="#" class="m-header-link " @click="selectSite('abaata')" v-else>
                                            <div class="m-header-link__icon">
                                              <img src="../assets/img/header/header__contacts.svg" alt="">
                                            </div>
                                            <div class="m-header-link__text">Абаата Гагра</div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="m-header__box">
                        <div class="m-header__item">
                            <div class="m-header-nav">
                                <div class="m-header-nav__btn-box">
                                    <router-link :to="'/'+$root.site+'/booking/'" class="m-header-nav__btn">Забронировать</router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div v-if="widthWindow <= 768" class="m-header__inner inner">
            <div class="m-header__wrapper">
                <div class="m-header__box">
                    <div class="m-header__item">
                        <div class="m-header-menu">
                            <button class="m-header-menu-btn js-m-header-link" type="button">
                                <div v-if="!burgerMenu" @click="burgerMenu = !burgerMenu" class="m-header-menu-btn__list">
                                    <img src="../assets/img/header/burger.svg" alt="">
                                </div>
                                <div v-if="burgerMenu && !subMenu && subMenu !== 0" @click="burgerMenu = !burgerMenu" class="m-header-menu-btn__list">
                                    <img src="../assets/img/header/close.png" alt="">
                                </div>
                                <div v-if="burgerMenu && typeof subMenu === 'number'" @click="subMenu = false" class="m-header-menu-btn__list">
                                    <img src="../assets/img/icon/next-arrow.svg" style="padding-top: 5px" alt="">
                                </div>
                                <!--a href="/search" style="margin-left: 15px" class="m-header-menu-btn__list">
                                    <img src="../assets/img/header/lupa.svg" alt="">
                                </a-->
                            </button>
                        </div>
                    </div>
                </div>
                <div class="m-header__box">
                    <div class="m-header__item">
                        <div class="m-header-logo">
                            <router-link :to="'/'+$root.site" class="m-header-logo__link">
                              <div class="m-newYear-header-logo" v-if="$root.site === 'abaata'">
                                <img class="m-newYear-header-logo__main" src="../assets/img/header/header__logo.svg" alt="">
<!--                                <div class="m-newYear-header-logo__cap">-->
<!--                                  <img src="../assets/img/Home/cap_logo.svg" alt="" >-->
<!--                                </div>-->
                              </div>
<!--                              <img src="../assets/img/header/header__logo.svg" alt="abaata" v-if="$root.site === 'abaata'">-->
                              <div class="m-newYear-header-logo" v-else>
                                <img class="m-newYear-header-logo__main" src="../assets/general/abaata.svg" alt="">
<!--                                <div class="m-newYear-header-logo__cap">-->
<!--                                  <img src="../assets/img/Home/cap_logo.svg" alt="" >-->
<!--                                </div>-->
                              </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="m-header__box" v-if="widthWindow > 500">
                    <div class="m-header__item" style="display: flex">
                        <div class="m-header-nav__btn-box">
                            <router-link :to="'/'+$root.site+'/booking/'" class="m-header-nav__btn">Забронировать</router-link>
                        </div>
                        <!--div class="m-header-lang">
                            <button type="button" class="m-header-lang__btn js-lang-ru-link">RU</button>
                            <button type="button" class="m-header-lang__btn js-lang-eng-link _active">ENG</button>
                        </div-->
                    </div>
                </div>
                <!--div v-if="widthWindow < 500" class="m-header__box">
                    <div class="m-header__item" style="margin-right: 10px; color: #ffffff">RU</div>
                </div-->
                <div v-if="widthWindow < 500" class="m-header__box" style="visibility: hidden">
                    <div><img src="../assets/img/header/phone.svg" alt=""></div>
                </div>
                <div class="m-header__box" hidden>
                    <div class="m-header__item">
                        <div class="m-header-lang">
                            <button type="button" href="/en/" class="m-header-lang__link">ENG</button>
                        </div>
                        <div class="m-header-phone">
                            <a href="tel:+78005505271" class="wstd_phone-number m-header-phone__link  rphone-tel-tel">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.9657 14.8672C18.4744 14.3556 17.8817 14.082 17.2536 14.082C16.6306 14.082 16.0329 14.3505 15.5213 14.8621L13.9206 16.4577C13.7889 16.3868 13.6572 16.3209 13.5306 16.2551C13.3482 16.1639 13.176 16.0778 13.0291 15.9866C11.5298 15.0343 10.1672 13.7933 8.86033 12.1876C8.22716 11.3873 7.80167 10.7136 7.49268 10.0297C7.90804 9.64983 8.29301 9.25474 8.66785 8.87483C8.80968 8.733 8.95151 8.58611 9.09334 8.44428C10.1571 7.38055 10.1571 6.00277 9.09334 4.93905L7.7105 3.5562C7.55347 3.39918 7.39138 3.23708 7.23942 3.07499C6.93549 2.76094 6.61638 2.43676 6.28713 2.13283C5.79579 1.64656 5.20821 1.38823 4.59023 1.38823C3.97226 1.38823 3.37454 1.64656 2.86801 2.13283C2.86294 2.1379 2.86294 2.1379 2.85788 2.14297L1.13565 3.88039C0.487286 4.52875 0.117514 5.31895 0.0364682 6.23578C-0.0851005 7.71487 0.350521 9.09264 0.684835 9.99428C1.50542 12.2078 2.73124 14.2593 4.55984 16.4577C6.77847 19.1069 9.44792 21.1989 12.4973 22.6729C13.6623 23.225 15.2174 23.8784 16.9548 23.9899C17.0612 23.9949 17.1726 24 17.2739 24C18.444 24 19.4267 23.5796 20.1966 22.7438C20.2017 22.7337 20.2118 22.7286 20.2169 22.7185C20.4803 22.3994 20.7842 22.1106 21.1033 21.8016C21.3211 21.594 21.544 21.3762 21.7618 21.1482C22.2633 20.6265 22.5267 20.0186 22.5267 19.3956C22.5267 18.7675 22.2582 18.1647 21.7466 17.6582L18.9657 14.8672Z" fill="#625948"/>
                                    <path d="M12.9684 5.70896C14.2955 5.93183 15.501 6.55994 16.4634 7.52236C17.4259 8.48477 18.0489 9.69033 18.2768 11.0175C18.3326 11.3518 18.6213 11.5848 18.9505 11.5848C18.9911 11.5848 19.0265 11.5797 19.067 11.5746C19.4419 11.5139 19.6901 11.1593 19.6293 10.7844C19.3558 9.17873 18.596 7.71484 17.436 6.55487C16.276 5.3949 14.8121 4.6351 13.2064 4.36157C12.8316 4.30078 12.4821 4.54899 12.4162 4.91876C12.3504 5.28853 12.5935 5.64817 12.9684 5.70896Z" fill="#625948"/>
                                    <path d="M23.9703 10.5869C23.5195 7.94282 22.2734 5.53677 20.3587 3.62206C18.444 1.70735 16.038 0.461273 13.3938 0.0104558C13.0241 -0.0553939 12.6746 0.197874 12.6087 0.567646C12.5479 0.942483 12.7961 1.29199 13.171 1.35784C15.5314 1.75801 17.6842 2.87745 19.3963 4.58448C21.1084 6.29657 22.2228 8.44935 22.6229 10.8098C22.6786 11.1441 22.9674 11.3771 23.2966 11.3771C23.3371 11.3771 23.3726 11.3721 23.4131 11.367C23.7829 11.3113 24.0362 10.9567 23.9703 10.5869Z" fill="#625948"/>
                                </svg>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <transition
                name="v-transition-burger"
        >
            <nav v-if="burgerMenu" class="m-nav js-m-nav">
                <div class="m-nav__wrap">
                    <div :class="{'_active': typeof subMenu === 'number'}" class="m-nav-big js-m-nav-big">
                        <div class="m-nav-big__inner inner">
                            <div class="m-nav-big__list">
                                <slot  v-for="(m, index) in menu[$root.site]">
                                  <label :key="'burger'+index" @click="subMenu = index" v-if="m.child">
                                    <div class="m-nav-big__box  js-nav-big-link">
                                      <div class="m-nav-big__icon-box">
                                        <div :style="{
                                        width: '40px',
                                        height: '40px',
                                        backgroundColor: (subMenu !== false && subMenu === index?'#8a724e': typeof subMenu !== 'number'? '#fff' : '#3f3f3f'),
                                        mask: 'url('+m.img+') no-repeat center / contain',
                                        webkitMask: 'url('+m.img+') no-repeat center / contain',
                                      }"></div>
                                      </div>
                                      <div v-if="!subMenu && subMenu !== 0" class="m-nav-big__name">{{ m.name }}</div>
                                    </div></label>
                                  <router-link tag="label" :key="'burger'+index" :to="'/'+$root.site+m.link" v-else>
                                    <div class="m-nav-big__box  js-nav-big-link">
                                      <div class="m-nav-big__icon-box">
                                        <div :style="{
                                        width: '40px',
                                        height: '40px',
                                        backgroundColor: (subMenu !== false && subMenu === index?'#8a724e': typeof subMenu !== 'number'? '#fff' : '#3f3f3f'),
                                        mask: 'url('+m.img+') no-repeat center / contain',
                                        webkitMask: 'url('+m.img+') no-repeat center / contain',
                                      }"></div>
                                      </div>
                                      <div v-if="!subMenu && subMenu !== 0" class="m-nav-big__name">{{ m.name }}</div>
                                    </div></router-link>
                                </slot>
                            </div>
                        </div>
                        <div v-if="burgerMenu && !subMenu && subMenu !== 0" class="m-nav-big__footer">
                            <div class="m-nav-big-min">
                                <div class="m-nav-big-min__inner inner">
                                    <div class="m-nav-big-min__list">
                                        <!--div class="m-nav-big-min__box">
                                            <a href="/affiche/" class="m-nav-big-min__link" target="_self">Афиша</a>
                                        </div-->
                                        <div class="m-nav-big-min__box" v-if="$root.site === 'abaata'">
                                            <router-link to="/abaata/offers/" class="m-nav-big-min__link" target="_self">Спецпредложения</router-link>
                                        </div>
                                      <div class="m-nav-big-min__box" v-if="$root.site === 'abaata'">
                                        <router-link to="/abaata/price" class="m-nav-big-min__link" target="_self">Цены</router-link>
                                      </div>
<!--                                      <div class="m-nav-big-min__box" v-if="$root.site === 'picunda'">
                                        <router-link to="/picunda/price" class="m-nav-big-min__link" target="_self">Цены</router-link>
                                      </div>-->
                                      <div class="m-nav-big-min__box" v-if="$root.site === 'picunda'">
                                        <router-link to="/picunda/services" class="m-nav-big-min__link" target="_self">Развлечения</router-link>
                                      </div>
                                      <div class="m-nav-big-min__box" v-if="$root.site === 'abaata'">
                                        <router-link to="/abaata/hair-clinic" class="m-nav-big-min__link" target="_self">Клиника  трансплантации волос доктора Халила Коча</router-link>
                                      </div>
                                        <!--div class="m-nav-big-min__box">
                                            <a href="/concierge-service/" class="m-nav-big-min__link" target="_self">Консьерж сервис</a>
                                        </div-->
                                      <div class="m-nav-big-min__box">
                                        <router-link :to="'/'+$root.site+'/about/contacts/'" class="m-nav-big-min__link" target="_self">Контакты</router-link>
                                      </div>
                                        <div class="m-nav-big-min__box">
                                            <router-link :to="'/'+$root.site+'/booking/'" class="m-nav-big-min__link _outline">Забронировать</router-link>
                                        </div>
                                      <div class="m-nav-big-min__box">
                                        <a href="#" class="m-nav-big-min__link" @click="selectSite('picunda')" v-if="$root.site === 'abaata'">Абаата Пицунда</a>
                                        <a href="#" class="m-nav-big-min__link"  @click="selectSite('abaata')" v-else>Абаата Гагра</a>
                                      </div>
                                        <div class="m-nav-big-min__box">
                                            <div style="margin-top:10px;min-height:60px;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="menu[$root.site][subMenu] && menu[$root.site][subMenu].child && menu[$root.site][subMenu].child.length"
                            class="_active m-nav-next  js-m-next-nav">
                        <div class="m-nav-next__inner inner">
                            <div class="_active m-nav-next__wrap js-m-nav-wrap">
                                <div class="m-nav-next__list">
                                    <div class="m-nav-next__box" v-for="child in menu[$root.site][subMenu].child" :key="'burger'+child.link" @click="subMenu = false, burgerMenu = false, isSubMenu = false">
                                        <router-link :to="'/'+$root.site+child.link" class="m-nav-next__link" target="_self">{{child.name}}</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </transition>
    </header>
</template>

<script>
    export default {
        name: "Header",
        data () {
            return {
                lang: 'ru',
                widthWindow: null,
                burgerMenu: false,
                isSubMenu: false,
                subMenu: false,
                menu: {
                  abaata: [
                    {
                      link: '/about/',
                      img: '/img/header/menu/kompleks.svg',
                      name: 'О комплексе',
                      child: [
                        {
                          link: '/about/',
                          name: 'О комплексе'
                        },
                        {
                          link: '/about/gallery/',
                          name: 'Галерея'
                        },
                        {
                          link: '/about/contacts/',
                          name: 'Контакты'
                        },
                        {
                          link: '/about/feedback/',
                          name: 'Обратная связь'
                        },
                        {
                          link: '/about/web-cams/',
                          name: 'Веб камеры'
                        },
                      ]
                    },
                    {
                      link: '/offers/',
                      img: '/img/header/menu/razmeshenie.svg',
                      name: 'Размещение',
                      child: [
                        {
                          link: '/offers/3',
                          name: 'Новые корпуса'
                        },
                        {
                          link: '/offers/1',
                          name: 'Корпус «Шале»'
                        },
                        {
                          link: '/offer/15',
                          name: 'VIP Вилла'
                        }
                      ]
                    },
                    {
                      link: '/restaurants/',
                      img: '/img/header/menu/bars-restorans.svg',
                      name: 'Бары и рестораны',
                      child: [
                        {
                          link: '/restaurant/67',
                          name: 'Пляжный бар'
                        },
                        {
                          link: '/restaurant/66',
                          name: 'Кафе «Чайхона Абаата»'
                        },
                        {
                          link: '/restaurant/50',
                          name: 'Ресторан «Абаата»'
                        },
                      ]
                    },
                    {
                      link: '/services/',
                      img: '/img/header/menu/razvlexhen.svg',
                      name: 'Развлечения',
                      child: [
                        {
                          link: '/service/16',
                          name: 'Бассейн'
                        },
                        {
                          link: '/service/35789',
                          name: 'Бильярд'
                        },
                        {
                          link: '/service/79',
                          name: 'Тренажерный зал'
                        },
                        {
                          link: '/service/20',
                          name: 'Теннисный корт'
                        },
                        {
                          link: '/service/19',
                          name: 'Трансфер'
                        },
                        {
                          link: '/service/18',
                          name: 'Массаж'
                        },
                        {
                          link: '/service/42',
                          name: 'Экскурсии'
                        },
                      ]
                    },
                    {
                      link: '/service/264',
                      img: '/img/header/menu/spa.svg',
                      name: 'Русская баня'
                    },
                    {
                      link: '/service/37028',
                      img: '/img/header/menu/spa.svg',
                      name: 'SPA'
                    }
                    /*{
                      link: '/spa/',
                      img: '/img/header/menu/spa.svg',
                      name: 'SPA',
                      child: [
                        {
                          link: '/service/264',
                          name: 'Русская баня'
                        },
                        {
                          link: '/service/37028',
                          name: 'Подогреваемый бассейн с противотоком и морской водой'
                        },
                        {
                          link: '/service/37029',
                          name: 'Бассейн с гидромассажем для детей'
                        },
                        {
                          link: '/service/37030',
                          name: 'Турецкий хамам'
                        },
                        {
                          link: '/service/37031',
                          name: 'Финская сауна'
                        }
                      ]
                    }*/
                  ],
                  picunda: [
                    {
                      link: '/offers/',
                      img: '/img/header/menu/razmeshenie.svg',
                      name: 'Размещение'
                    },
                  ]
                }
            }
        },
        computed: {
        },
        methods: {
          selectSite (site) {
            this.$root.site = site
            this.$router.push('/'+site)
          },
            mobileWindow () {
                const screenWidth = window.screen.width
                this.widthWindow = screenWidth
                console.log(this.widthWindow)
            },
            scroll () {
                let prevScrollpos = window.pageYOffset;
                window.onscroll = function() {
                    let currentScrollPos = window.pageYOffset;
                    if (prevScrollpos > currentScrollPos) {
                        document.getElementById("header").style.top = "0";
                    } else {
                        document.getElementById("header").style.top = "-100px";
                    }
                    prevScrollpos = currentScrollPos;
                }
            }
        },
      watch: {
        $route () {
          this.subMenu = false
          this.burgerMenu = false
          this.isSubMenu = false
        }
      },
        mounted () {
            this.mobileWindow()
            this.scroll()
            if (this.burgerMenu) {
                return document.body.style.overflowY = 'hidden'
            }
        },
    }
</script>

<style scoped lang="scss">

.newYear-header-logo{
  position: relative;
  .newYear-header-logo__main{
    width: 155px;
    height: 87px;
  }
  .newYear-header-logo__cap{
    position: absolute;
    top:15px;
    right: -15px;
    img{
      width: 23px;
      height: 15px;
    }
  }
}
.m-newYear-header-logo{
  position: relative;
  .m-newYear-header-logo__main{
    width: 128px;
    height: 48px;
  }
  .m-newYear-header-logo__cap{
    position: absolute;
    top:-1px;
    left: 120px;
    img{
      width: 23px;
      height: 15px;
    }
  }
}
    .m-header {
        background-color: rgba(35, 31, 32, 0.8);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9999;
    }
    .m-header._active .m-header__wrap {
        position: relative;
    }
    .m-header__logo img {
        max-height: 77px;
        max-width: 114px;
    }
    .m-header-search {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        max-width: calc(100% - 40px);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .m-header-search:not(._active) {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
    .m-header-search__input {
        width: 100%;
        height: 40px;
        background-color: transparent;
        color: #fff;
        border: 2px solid #8a724a;
        padding-left: 12px;
        padding-right: 12px;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
    }
    .m-header-search__input::placeholder {
        opacity: 1;
        color: #fff;
    }
    .m-header-menu-wrap__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .m-header._type-2 {
        background-color: #fff;
        box-shadow: 0 2px 7px rgba(0, 0, 0, 0.081267);
    }
    .m-header._type-2 .m-header-nav__link {
        color: #231f20;
    }
    .m-header._type-2 .m-header-nav__link._active,
    .m-header._type-2 .m-header-nav__link:hover {
        color: #8a724a;
    }
    .m-header._type-2 .m-header-nav__list {
        margin-right: 0;
        padding-right: 65px;
        width: calc(100% - 130px);
        padding-left: 40px;
    }
    .m-header._type-2 .m-header-nav__list._full {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    .m-header._type-2 .m-header-nav__list._full .m-header-nav__link {
        font-size: 10px;
        text-align: center;
    }
    .m-header._type-2 .m-header-nav__list._full .m-header-nav__link:after {
        display: none;
    }
    .m-header._type-2 .m-header-nav__list._full .m-header-nav__box {
        padding-left: 6px;
        padding-right: 6px;
    }
    .m-header._type-2 .m-header-nav__wrapper {
        justify-content: space-between;
    }
    .m-header._type-2 .m-header-nav__btn-box {
        min-width: 130px;
        text-align: right;
    }
    .m-header._type-3 .m-header-nav__list {
        margin-right: 0;
        padding-right: 65px;
        width: calc(100% - 60px);
        padding-left: 40px;
    }
    .m-header._type-3 .m-header-nav__list._full {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    .m-header._type-3 .m-header-nav__list._full .m-header-nav__link {
        font-size: 10px;
        text-align: center;
    }
    .m-header._type-3 .m-header-nav__list._full .m-header-nav__box {
        padding-left: 6px;
        padding-right: 6px;
    }
    .m-header._type-3 .m-header-nav__wrapper {
        justify-content: space-between;
    }
    .m-header._type-3 .m-header-nav__btn-box {
        min-width: 60px;
        text-align: right;
    }
    .m-header:hover ~ .page:after {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }
    .m-header__inner {
        transition: all 0.4s;
    }
    .m-header__inner._active {
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
    }
    .m-header__wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .m-header__box:nth-child(1) {
        width: 200px;
        @media (max-width: 500px) {
            max-width: 100px;
        }
    }
    .m-header__box:nth-child(2) {
        width: calc(100% - 200px);
    }
    .m-header-line__wrapper {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .m-header-line__box:not(:last-child) {
        margin-right: 25px;
    }
    @media (max-width: 1200px) {
        .m-header-line__box:not(:last-child) {
            margin-right: 12px;
        }
    }
    .m-header-menu._hover .m-header-menu-wrap,
    .m-header-menu:hover .m-header-menu-wrap {
        opacity: 1;
        pointer-events: auto;
        visibility: visible;
        transition: all 0.4s;
    }
    .m-header-menu._hover .m-header-menu-wrap:before,
    .m-header-menu:hover .m-header-menu-wrap:before {
        width: 100%;
        transition: width 0.6s;
    }
    .m-header-menu._hover .m-header-menu-btn,
    .m-header-menu:hover .m-header-menu-btn {
        color: #8a724a;
    }
    .m-header-menu:hover .m-header-menu-btn:after,
    .m-header-menu:hover .m-header-menu-btn:after {
        border-bottom-width: 10px;
    }
    .m-header-menu._hover .m-header-menu-btn__box:nth-child(1),
    .m-header-menu:hover .m-header-menu-btn__box:nth-child(1) {
        transform: rotate(-45deg);
    }
    .m-header-menu._hover .m-header-menu-btn__box:nth-child(2),
    .m-header-menu:hover .m-header-menu-btn__box:nth-child(2) {
        width: 0;
        transform: rotate(-45deg);
        opacity: 0;
    }
    .m-header-menu._hover .m-header-menu-btn__box:nth-child(3),
    .m-header-menu:hover .m-header-menu-btn__box:nth-child(3) {
        transform: rotate(45deg);
    }
    .m-header-menu-sub {
        padding-bottom: 78px;
        position: absolute;
        left: 0;
        width: 100%;
        opacity: 0;
        margin-top: -3px;
        pointer-events: none;
        visibility: hidden;
        transition: all 0.2s;
    }
    .m-header-menu-sub::before {
        background: rgba(138, 114, 74, 0.8);
        margin: 0;
        height: 50px;
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        display: block;
        box-shadow: 0 2px 7px rgba(0, 0, 0, 0.08);
    }
    .m-header-menu-sub__wrapper {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .m-header-menu-sub__box {
        padding-left: 20px;
        padding-right: 20px;
    }
    .m-header-menu-sub__link {
        text-transform: uppercase;
        letter-spacing: 0.85px;
        font-size: 10px;
        font-weight: 500;
        text-decoration: none;
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        transition: all 0.4s;
        position: relative;
    }
    .m-header-menu-sub__link span {
        display: inline;
        vertical-align: top;
        position: relative;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .m-header-menu-sub__link span:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        will-change: width;
        transition: width 0.4s;
        height: 1px;
        background-color: #fff;
    }
    .m-header-menu-sub__link._active span:after,
    .m-header-menu-sub__link:hover span:after {
        width: 100%;
    }
    .m-header-menu-wrap {
        position: absolute;
        left: 0;
        width: 100%;
        background-color: rgba(35, 31, 32, 0.8);
        transition: all 0.2s;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        z-index: 100;
    }
    .m-header-menu-wrap:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        will-change: width;
        height: 1px;
        background-color: #8a724a;
        transition: width 0.2s;
        z-index: 10;
    }
    .m-header-menu-wrap__wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .m-header-menu-wrap__item._active .m-header-menu-sub,
    .m-header-menu-wrap__item:hover .m-header-menu-sub {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        transition: all 0.4s;
    }
    .m-header-menu-wrap__item:hover {
        margin: 0 -65px;
    }
    .m-header-menu-wrap__item._active .m-header-menu-wrap__link,
    .m-header-menu-wrap__item:hover .m-header-menu-wrap__link {
        color: #8a724a;
        margin: 0 65px;
    }
    .m-header-menu-wrap__item._active .m-header-menu-wrap__link._submenu:after,
    .m-header-menu-wrap__item:hover .m-header-menu-wrap__link._submenu:after {
        border-bottom-width: 10px;
    }
    .m-header-menu-wrap__item._active .m-header-menu-wrap__icon,
    .m-header-menu-wrap__item:hover .m-header-menu-wrap__icon {
        fill: #8a724a;
    }
    .m-header-menu-wrap__icon-box {
        height: 50px;
        margin-bottom: 10px;
    }
    .m-header-menu-wrap__icon {
        fill: #fff;
        display: block;
        max-height: 40px;
        max-width: 35px;
        margin-left: auto;
        margin-right: auto;
        transition: fill 0.4s;
    }
    .m-header-menu-wrap__name {
        text-transform: uppercase;
        letter-spacing: 0.7px;
        font-size: 10px;
        font-weight: 500;
    }
    .m-header-menu-wrap__link {
        text-decoration: none;
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 120px;
        position: relative;
    }
    .m-header-menu-wrap__link:after {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 2px;
        border: 8px solid transparent;
        border-bottom: 0 solid #8a724a;
        transition: all 0.4s;
    }
    .m-header-menu-btn {
        color: #fff;
        background-color: transparent;
        border: 0;
        height: 80px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        letter-spacing: 0.7px;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.4s;
        position: relative;
        margin-right: -20px;
        padding-right: 20px;
        margin-left: -20px;
        padding-left: 20px;
    }
    @media (max-width: 1200px) {
        .m-header-menu-btn {
            font-size: 10px;
        }
    }
    .m-header-menu-btn:after {
        content: "";
        display: block;
        position: absolute;
        left: 22px;
        bottom: 0;
        border: 8px solid transparent;
        border-bottom: 0 solid #8a724a;
        transition: all 0.4s;
    }
    .m-header-menu-btn__list {
        width: 22px;
        position: relative;
        height: 18px;
    }
    .m-header-menu-btn__box {
        height: 3px;
        background-color: #8a724a;
        width: 100%;
        position: absolute;
        transition: all 0.4s;
        will-change: transform, width;
        left: 0;
    }
    .m-header-menu-btn__box:nth-child(1) {
        top: 0;
        transform-origin: 20px 3px;
    }
    .m-header-menu-btn__box:nth-child(2) {
        top: 7px;
    }
    .m-header-menu-btn__box:nth-child(3) {
        top: 14px;
        transform-origin: 20px 0;
    }
    .m-header-menu-btn__text {
        padding-left: 12px;
    }
    .m-header-lang {
        display: flex;
        align-items: center;
        margin-left: 25px;
    }
    @media (max-width: 1300px) {
        .m-header-lang {
            margin-left: 12px;
        }
    }
    .m-header-lang__btn {
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 0.57px;
        padding-left: 8px;
        padding-right: 8px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        font-weight: 700;
        transition: all 0.4s;
        border: 0;
        background-color: transparent;
        color: #fff;
        text-decoration: none;
    }
    .m-header-lang__btn:hover {
        color: #c8b99f;
    }
    .m-header-lang__btn._active {
        color: #c8b99f;
        cursor: default;
    }
    .m-header-lang__btn:nth-child(1):after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        height: 12px;
        transform: translateY(-50%);
        width: 1px;
        background-color: #fff;
    }
    .m-header-link {
        position: relative;
        text-decoration: none;
        height: 80px;
        display: flex;
        color: #fff;
        transition: color 0.4s;
        align-items: center;
        white-space: nowrap;
    }
    .m-header-link._active,
    .m-header-link:hover {
        color: #8a724a;
    }
    .m-header-link__icon {
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        width: 23px;
        height: 20px;
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
    }
    /*.m-header-link__icon._type-1 {*/
    /*    background-image: url(../i/i-calendar.svg);*/
    /*}*/
    /*.m-header-link__icon._type-2 {*/
    /*    background-image: url(../i/booking-icon.svg);*/
    /*}*/
    /*.m-header-link__icon._type-3 {*/
    /*    background-image: url(../i/transport-icon.svg);*/
    /*}*/
    /*.m-header-link__icon._type-4 {*/
    /*    background-image: url(../i/tree-icon.svg);*/
    /*}*/
    .m-header-link__icon svg {
        fill: #8a724a;
        width: 23px;
        height: 24px;
        display: block;
    }
    .m-header-link__text {
        padding-left: 35px;
        text-transform: uppercase;
        letter-spacing: 0.7px;
        font-size: 12px;
        font-weight: 500;
    }
    @media (max-width: 1200px) {
        .m-header-link__text {
            font-size: 10px;
        }
    }
    .m-header-nav {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
    }
    .m-header-nav__list {
        box-sizing: border-box;
        margin-right: 40px;
    }
    .m-header-nav__wrapper {
        display: flex;
        align-items: center;
    }
    .m-header-nav__box {
        padding-left: 15px;
        padding-right: 15px;
    }
    .m-header-nav__search-box {
        margin-right: 30px;
    }
    @media (max-width: 1300px) {
        .m-header-nav__search-box {
            margin-right: 12px;
        }
    }
    @media (max-width: 1024px) {
        .m-header-nav__search-box {
            display: none;
        }
    }
    .m-header-nav__search-btn {
        cursor: pointer;
        position: relative;
        display: block;
        border: 0;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 12px;
        width: 22px;
        height: 22px;
        padding-left: 0;
        padding-right: 0;
        letter-spacing: 0.7px;
        background-color: transparent;
        transition: color 0.4s;
    }
    @media (max-width: 1200px) {
        .m-header-nav__search-btn {
            font-size: 10px;
        }
    }
    .m-header-nav__search-btn:hover {
        color: #8a724a;
    }
    .m-header-nav__search-btn:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 22px;
        height: 22px;
        background: url(../assets/img/header/lupa.svg) 50% 50% / contain no-repeat;
    }
    .m-header-nav__item._active .m-header-nav-in,
    .m-header-nav__item:hover .m-header-nav-in {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }
    .m-header-nav__item._active .m-header-nav__link,
    .m-header-nav__item:hover .m-header-nav__link {
        color: #8a724a;
    }
    .m-header-nav__item._active .m-header-nav__link._submenu:after,
    .m-header-nav__item:hover .m-header-nav__link._submenu:after {
        border-bottom-width: 10px;
    }
    .m-header-nav__link {
        font-weight: 700;
        color: #fff;
        letter-spacing: 0.7px;
        font-size: 12px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        transition: all 0.4s;
        height: 80px;
        position: relative;
    }
    .m-header-nav__link:after {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        border: 8px solid transparent;
        border-bottom: 0 solid #8a724a;
        transition: all 0.4s;
    }
    .m-header-nav__btn {
        padding-left: 12px;
        padding-right: 12px;
        height: 38px;
        border: 2px solid #fff;
        font-weight: 500;
        color: #fff;
        letter-spacing: 0.7px;
        font-size: 12px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        transition: all 0.4s;
    }
    @media (max-width: 1200px) {
        .m-header-nav__btn {
            font-size: 10px;
        }
    }
    .m-header-nav__btn:hover {
        background-color: #fff;
        color: #333;
    }
    .m-header-nav__phone {
        font-weight: 700;
        font-size: 13px;
        letter-spacing: 0.75px;
        color: #231f20;
        text-decoration: none;
        white-space: nowrap;
    }
    .m-header-nav-in {
        background-color: rgba(35, 31, 32, 0.8);
        box-shadow: 0 2px 7px rgba(0, 0, 0, 0.081267);
        position: absolute;
        left: 0;
        width: 100%;
        transition: all 0.4s;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
    }
    .m-header-nav-in:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        will-change: width;
        height: 1px;
        background-color: #8a724a;
        transition: width 0.2s;
        z-index: 10;
    }
    .m-header-nav-in__wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .m-header-nav-in__box {
        padding-left: 10px;
        padding-right: 10px;
    }
    .m-header-nav-in__link {
        text-transform: uppercase;
        letter-spacing: 0.85px;
        font-size: 10px;
        font-weight: 500;
        text-decoration: none;
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        transition: all 0.4s;
        position: relative;
    }
    .m-header-nav-in__link span {
        display: inline;
        position: relative;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .m-header-nav-in__link span:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        will-change: width;
        transition: width 0.4s;
        height: 1px;
        background-color: #fff;
    }
    .m-header-nav-in__link._active span:after,
    .m-header-nav-in__link:hover span:after {
        width: 100%;
    }
    .m-nav-fixed {
        background-color: rgba(138, 114, 74, 0.8);
        box-shadow: 0 2px 7px rgba(0, 0, 0, 0.08);
        position: fixed;
        top: 80px;
        left: 0;
        width: 100%;
        transition: all 0.4s;
        z-index: 50;
    }
    .m-nav-fixed._offset {
        transform: translateY(100%);
    }
    .m-nav-fixed:not(._active) {
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
    }
    .m-nav-fixed__wrapper {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .m-nav-fixed__box {
        padding-left: 20px;
        padding-right: 20px;
    }
    .m-nav-fixed__link {
        text-transform: uppercase;
        letter-spacing: 0.85px;
        font-size: 11px;
        font-weight: 500;
        text-decoration: none;
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        transition: all 0.4s;
        position: relative;
    }
    .m-nav-fixed__link span {
        display: inline;
        vertical-align: top;
        position: relative;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .m-nav-fixed__link span:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        will-change: width;
        transition: width 0.4s;
        height: 1px;
        background-color: #fff;
    }
    .m-nav-fixed__link._active span:after,
    .m-nav-fixed__link:hover span:after {
        width: 100%;
    }
    @media (max-width: 1200px) {
        .m-header-nav__list {
            margin-right: 20px;
        }
        .m-header-menu-sub__wrapper {
            justify-content: flex-start;
        }
        .m-header-nav-in__link span:after {
            display: none;
        }
        .m-header-menu-sub__link span:after {
            display: none;
        }
        .m-header-menu-sub__box {
            padding-left: 6px;
            padding-right: 6px;
        }
    }
    @media (max-width: 1024px) {
        .m-header-nav-in__link {
            font-size: 9px;
        }
        .m-header-nav-in__box {
            padding-left: 6px;
            padding-right: 6px;
        }
        .m-header-menu-wrap__name {
            font-size: 9px;
        }
        .m-header-menu-wrap__icon-box {
            height: 40px;
            margin-bottom: 8px;
        }
        .m-header-menu-wrap__icon {
            max-height: 30px;
            max-width: 30px;
        }
        .m-header-menu-wrap__link {
            height: 100px;
        }
        .m-header-nav__link {
            font-size: 10px;
        }
        .m-header-nav__box {
            padding-left: 6px;
            padding-right: 6px;
            text-align: center;
        }
        .m-header._type-2 .m-header-nav__list {
            padding-left: 20px;
            padding-right: 40px;
        }
        .m-header._type-3 .m-header-nav__list {
            padding-left: 20px;
            padding-right: 40px;
        }
    }
    .m-header._type-3 .m-header-nav__item._active .m-header-nav-in:before {
        transition: width 0.4s;
        width: 100%;
    }
    .m-header._type-3 .m-header-nav__item:hover .m-header-nav-in:before {
        transition: width 0.4s;
        width: 100%;
    }
    /*.m-header-nav__phone {*/
    /*    width: 28px;*/
    /*    height: 28px;*/
    /*    background: url(../i/icons/main-menu/telephone.svg) 50% 50% / contain no-repeat;*/
    /*    display: inline-block;*/
    /*    vertical-align: top;*/
    /*    transition: opacity 0.4s;*/
    /*}*/
    .m-header-nav__phone:hover {
        opacity: 0.7;
    }
    .search-sec {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .search-sec__title {
        font-size: 34px;
        letter-spacing: -0.49px;
        color: #8a724e;
        text-transform: uppercase;
        font-weight: 300;
    }
    .search-sec__wrap {
        max-width: 994px;
        margin-left: auto;
        margin-right: auto;
    }
    .search-sec-form {
        margin-top: 34px;
        display: block;
    }
    .search-sec-form__wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
    }
    .search-sec-form__input-box {
        width: calc(100% - 200px);
        padding-right: 40px;
        box-sizing: border-box !important;
    }
    .search-sec-form__input {
        width: 100%;
        border: 0;
        border-bottom: 1px solid #c8b99f;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.69px;
        height: 55px;
        display: block;
        text-transform: uppercase;
        padding: 0;
        box-sizing: border-box !important;
        transition: border-color 0.4s;
        color: #231f20;
    }
    .search-sec-form__input:focus {
        border-color: #8a724a;
    }
    .search-sec-form__input::placeholder {
        opacity: 1;
        color: #cecece;
    }
    .search-sec-form__btn-box {
        width: 200px;
    }
    .search-sec-form__btn {
        height: 55px;
        line-height: 54px;
        border: 1px solid #8a724a;
        border-radius: 8px;
        width: 100%;
        display: block;
        background: transparent;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.69px;
        color: #231f20;
        font-weight: 700;
        transition: all 0.4s;
    }
    .search-sec-form__btn:hover {
        background-color: #8a724a;
        color: #fff;
    }
    .search-sec__list {
        margin-top: 85px;
    }
    .search-sec__box:not(:last-child) {
        margin-bottom: 40px;
    }
    .search-sec__item {
        display: block;
        color: #231f20;
        text-decoration: none;
    }
    .search-sec__item:hover .search-sec__name {
        border-bottom-color: transparent;
    }
    .search-sec__data {
        font-size: 12px;
        letter-spacing: 0.44px;
        margin-bottom: 4px;
    }
    .search-sec__name {
        font-family: PFDinTextCondPro;
        color: #00e;
        font-weight: 100;
        font-size: 34px;
        display: inline;
        border-bottom: 1px solid #00e;
        transition: border-bottom-color 0.4s;
    }
    .search-sec__desc {
        margin-top: 12px;
        font-weight: 300;
        font-size: 18px;
        line-height: 1.6666666667;
        letter-spacing: 0.32px;
    }
    .search-sec-pagination {
        margin-top: 70px;
    }
    .search-sec-pagination__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .search-sec-pagination__box {
        padding: 6px 12px;
    }
    .search-sec-pagination__item {
        display: inline-block;
        vertical-align: top;
        color: #c8b99f;
        text-decoration: none;
        transition: color 0.4s;
    }
    .search-sec-pagination__item:hover {
        color: #8a724e;
    }
    .search-sec-pagination__item._active {
        color: #8a724e;
    }
    @media (max-width: 768px) {
        .search-sec {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .search-sec-form {
            margin-top: 12px;
        }
        .search-sec-form__input {
            height: 40px;
            border-bottom-color: #8a724e;
        }
        .search-sec-form__btn {
            height: 40px;
            line-height: 39px;
            color: #8a724e;
            border-color: #8a724e;
        }
        .search-sec-form__input-box {
            width: 100%;
            padding-right: 0;
        }
        .search-sec-form__btn-box {
            margin-top: 25px;
            max-width: 180px;
        }
        .search-sec__list {
            margin-top: 30px;
        }
        .search-sec__name {
            font-size: 24px;
        }
        .search-sec__desc {
            font-size: 14px;
            line-height: 1.7857142857;
        }
    }
    .m-header {
        transition: top 0.2s;
    }
    .top-media.__visible ~ .page .m-header {
        top: 48px;
    }
    nav{display:block;}
    a{background-color:transparent;-webkit-text-decoration-skip:objects;}
    a:active,a:hover{outline-width:0;}
    img{border-style:none;}
    svg:not(:root){overflow:hidden;}
    select{font:inherit;margin:0;outline:0;}
    select{text-transform:none;}
    ::-webkit-input-placeholder{color:#2C3039;opacity:.5;}
    .inner{-webkit-box-sizing:border-box;box-sizing:border-box;}
    .inner{padding-left:20px;padding-right:20px;}
    a{text-decoration:none;}
    select{-webkit-appearance:none;}
    img{display:block;}
    img{max-width:100%;}
    a.gflag{vertical-align:middle;font-size:21px;display:inline-block;padding:1px 0;width:26px;background-repeat:no-repeat;background-image:url(//gtranslate.net/flags/24.png);}
    a.gflag img{border:0;}
    a.gflag:hover{background-image:url(//gtranslate.net/flags/24a.png);}
    #google_translate_element2{display:none!important;}
    .m-nav{background-color:rgba(15,15,15,0.8);position:fixed;left:0;top:80px;height:calc(100% - 80px);z-index:1001;overflow:auto;will-change:transform;transition:transform .4s;width:100%;}
    .m-nav .inner{padding-left:10px;padding-right:10px;}
    .m-nav__wrap{width:100%;display:flex;}
    .m-nav-big{
        border-right:1px solid transparent;
        width:100%;
        &._active {
            width: 65px;
            border-right-color: #8A724E;
        }
    }
    .m-nav-big__list{padding-top:15px;padding-bottom:15px;}
    .m-nav-big__box{position:relative;cursor:pointer;will-change:width;transition:width .4s;min-height:52px;display:block;}
    .m-nav-big__box:not([href]):after{content:"";display:block;position:absolute;right:0;top:50%;transform:translateY(-50%);width:12px;height:3px;background:url(https://mriyaresort.com/mobile/i/dots-icon.svg) 50% 50%/contain no-repeat;transition:all .4s;}
    .m-nav-big__icon{fill:#fff;display:block;max-width:32px;max-height:30px;margin-left:auto;margin-right:auto;transition:fill .4s;}
    .m-nav-big__icon-box{position:absolute;left:0;top:50%;transform:translateY(-50%);width:32px;}
    .m-nav-big__name{margin-left: 13px;white-space:nowrap;color:#fff;font-size:12px;text-transform:uppercase;font-weight:700;letter-spacing:.57px;display:flex;align-items:center;width:100%;min-height:52px;padding-left:46px;transition:all .4s;}
    .m-nav-big__footer{position:relative;z-index:10;padding-bottom:20px;padding-top:20px;border-top:1px solid #8A724E;transition:all .4s;will-change:width,height;}
    .m-nav-big-min__box{text-align:center;}
    .m-nav-big-min__box:not(:last-child){margin-bottom:12px;}
    .m-nav-big-min__link{display:inline-block;vertical-align:top;position:relative;color:#fff;text-transform:uppercase;font-size:12px;letter-spacing:.69px;font-weight:700;padding-top:6px;padding-bottom:6px;}
    .m-nav-big-min__link._outline{border:2px solid #fff;padding:8px 12px;}
    .m-nav-next{width:calc(100% - 53px);will-change:width;padding-left:12px;padding-right:12px;}
    .m-nav-next:not(._active){width:0;}
    .m-nav-next:not(._active) .m-nav-next__wrap{display:none;}
    .m-nav-next__wrap{opacity:0;pointer-events:none;visibility:hidden;height:0;}
    .m-nav-next__wrap._active{opacity:1;pointer-events:auto;visibility:visible;}
    .m-nav-next__list{padding-top:16px;padding-bottom:16px;}
    .m-nav-next__link{padding-top:15px;padding-bottom:15px;display:block;font-weight:700;font-size:10px;color:#fff;text-transform:uppercase;letter-spacing:.85px;}
    .goog-te-gadget{font-family:arial;font-size:11px;color:#666;white-space:nowrap;}
    .goog-te-gadget img{vertical-align:middle;border:none;}
    .goog-te-combo{margin-left:4px;margin-right:4px;vertical-align:baseline;*vertical-align:middle;}
    .goog-te-gadget .goog-te-combo{margin:4px 0;}
    .goog-logo-link,.goog-logo-link:link,.goog-logo-link:visited,.goog-logo-link:hover,.goog-logo-link:active{font-size:12px;font-weight:bold;color:#444;text-decoration:none;}
    .goog-te-combo{font-family:arial;font-size:10pt;}
    .goog-te-gadget{font-family:arial;font-size:11px;color:#666;white-space:nowrap;}
    .goog-te-gadget img{vertical-align:middle;border:none;}
    .goog-te-combo{margin-left:4px;margin-right:4px;vertical-align:baseline;*vertical-align:middle;}
    .goog-te-gadget .goog-te-combo{margin:4px 0;}
    .goog-logo-link,.goog-logo-link:link,.goog-logo-link:visited,.goog-logo-link:hover,.goog-logo-link:active{font-size:12px;font-weight:bold;color:#444;text-decoration:none;}
    .goog-te-combo{font-family:arial;font-size:10pt;}
    .v-transition-burger-enter {
        transform: translateX(-100vw);
    }
    .v-transition-burger-enter-active {
        transition: all 0.5s;
    }
    .v-transition-burger-enter-to {
        transform: translateY(0);
    }
    .v-transition-burger-leave {
        transform: translateY(0);
    }
    .v-transition-burger-leave-active {
        transition: all 0.5s;
    }
    .v-transition-burger-leave-to {
        transform: translateX(-100vw);
    }
</style>
